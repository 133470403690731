export const dispatchAdManager = (setTargeting, cookies, page, segments) => {
  const idsOfBreadcrumb = page?.page?.breadcrumb?.map(getId);

  const object = {
    userId: cookies['B2W-UID'],
    macroRegion: cookies['macroRegion'],
    mesoRegion: cookies['mesoRegion'],
    legionRegion: cookies['legionRegion'],
    cep: cookies['persistentCep'],
    utm_campaign: cookies['utm_campaign'],
    utm_content: cookies['utm_content'],
    utm_medium: cookies['utm_medium'],
    utm_source: cookies['utm_source'],
    epar: cookies['b2wEPar'],
    aud: segments?.data?.segments?.segments,
    categoryId: idsOfBreadcrumb?.toString(),
  };

  for (const key in object) {
    if (object[key] !== undefined) {
      setTargeting(key, object[key]);
    }
  }
};

export const getId = value => value.id;

export const getQuantitySizes = (publication, key) =>
  Object.keys(publication).filter(item => item.includes(key)).length;
