import { useQuery, useApolloClient, QueryResult, OperationVariables } from '@apollo/client';
import { CookieHeadersQuery, GetSegmentsQuery } from '@catalogo/core-types';
import GetSegments from './query.graphql';
import cookieHeadersQuery from './cookie-headers.graphql';

export const useSegments = (): QueryResult<GetSegmentsQuery, OperationVariables> => {
  const client = useApolloClient();
  const cq = client.readQuery<CookieHeadersQuery>({
    query: cookieHeadersQuery,
  });

  const result = useQuery<GetSegmentsQuery>(GetSegments, {
    variables: {
      customerId: cq?.cookies['customer.id'],
    },
    skip: !cq?.cookies['customer.id'],
  });

  return result;
};
