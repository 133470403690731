import React from 'react';
import styled, { css } from 'styled-components';
import { withMidiaProgramatica } from '@catalogo/service-americanas-b2wads/src/hocs/midia-programatica';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';

export const B2wadsGoogleMidiaProgramatica = ({ id, zionSlideShow }) => (
  <Wrapper zionSlideShow={zionSlideShow} className="wrapper-b2wads">
    <Div id={id} />
  </Wrapper>
);

const Wrapper = styled.div`
  ${({ zionSlideShow }) => css`
    width: 100%;
    ${zionSlideShow &&
    css`
      width: 100vw;
    `}
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
  `}
`;
const Div = styled.div`
  width: 100%;
`;

export default compose(withCookies, withMidiaProgramatica)(B2wadsGoogleMidiaProgramatica);
