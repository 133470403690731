/**
 * @param {string} viewport example 1250x0
 * @param {string} sizesViewport example [["1250x0",[1296,162]],["728x0",[648,540]],["0x0",[648,540]]]
 * @return {string} example [[1296,162]]
 */
export const getSizeImagesInViewport = (viewport: string, sizesViewport: [number[] | string]) => {
  const array = sizesViewport
    .map(item => {
      if ((item[0] as string) === viewport) {
        return item[1];
      }
      return null;
    })
    .filter(item => !!item);
  return array;
};

/**
 * @param {string} sizesViewport example [["1250x0",[1296,162]],["728x0",[648,540]],["0x0",[648,540]]]
 * @return {string} example [[1296,162],[648,540]]
 */
export const getOnlySizeImages = (sizesViewport: [number[] | string]) => {
  const sizes: number[] = sizesViewport.map(item => item[1]) as number[];
  const uniqueSize: number[] = [];
  sizes.map(item => {
    if (JSON.stringify(uniqueSize).indexOf(JSON.stringify(item)) === -1) {
      uniqueSize.push(item);
    }
    return null;
  });
  return uniqueSize;
};
