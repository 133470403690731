export const getPits = match => {
  const term = match?.params?.term;
  const category = match?.params?.category;
  const productId = match?.params?.id;

  if (term) {
    return {
      key: 'term',
      value: [term],
    };
  }

  if (category) {
    return {
      key: 'category',
      value: [category],
    };
  }

  if (productId) {
    return {
      key: 'pit',
      value: [productId],
    };
  }

  return null;
};
