import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useSegments } from '@catalogo/core-segments';
import { dispatchAdManager } from '../helpers/midia-programatica';
import { pageBreadcrumb } from '../queries/page-breadcrumb.graphql';

export const useMidiaProgramatica = (
  callback,
  sizeImages1250,
  sizeImages728,
  sizeImages0,
  networkCode,
  adUnitPath,
  arrayAllImagesSizes,
  id,
  pits,
  enableLazyLoad,
  cookies,
  location
) => {
  const segments = useSegments();
  const client = useApolloClient();
  const page = client?.readQuery({
    query: pageBreadcrumb,
    variables: { path: location?.pathname },
  });

  useEffect(() => {
    sizeImages1250.push('fluid');
    sizeImages728.push('fluid');
    sizeImages0.push('fluid');

    const googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      const slot = googletag
        .defineSlot(`/${networkCode}/${adUnitPath}`, arrayAllImagesSizes, id)
        .addService(googletag.pubads());
      const mapping = googletag
        .sizeMapping()
        .addSize([1250, 0], sizeImages1250)
        .addSize([728, 0], sizeImages728)
        .addSize([0, 0], sizeImages0)
        .build();
      slot.defineSizeMapping(mapping);
      if (pits) {
        googletag.pubads().setTargeting(pits.key, pits.value);
      }

      dispatchAdManager(googletag.pubads().setTargeting, cookies, page, segments);

      googletag.pubads().collapseEmptyDivs();
      window.googletag.pubads().enableSingleRequest();
      enableLazyLoad &&
        window.googletag.pubads().enableLazyLoad({
          // Fetch slots within 5 viewports.
          fetchMarginPercent: 132,
          // Render slots within 2 viewports.
          renderMarginPercent: 200,
          // Double the above values on mobile, where viewports are smaller
          // and users tend to scroll faster.
          mobileScaling: 2.0,
        });
      googletag.enableServices();
      callback();
    });
  }, []);
};
