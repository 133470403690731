/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import React, { useState } from 'react';

import { Observer } from '@catalogo/theme-observer';
import { getPits } from '../helpers/pits';
import { getQuantitySizes } from '../helpers/midia-programatica';
import { getSizeImagesInViewport, getOnlySizeImages } from '../helpers/viewport';
import { useMidiaProgramatica } from '../hooks/midia-programatica';

export const withMidiaProgramatica = WrappedComponent => {
  const Enhanced = props => {
    const { _id: publisherId, networkCode, adUnitPath, zionSlideShow } = props.publication;
    const [isLoaded, setLoaded] = useState(false);
    const id = `div-gpt-ad-${publisherId}`;
    const googletag = (typeof window !== 'undefined' && window.googletag) || { cmd: [] };
    const sizesViewport = [];
    const quantity = getQuantitySizes(props.publication, 'viewport1_width');
    const quantityBanner = getQuantitySizes(props.publication, 'viewport1_banners_');

    const enableLazyLoad = !zionSlideShow;

    const getViewportWithSizeImages = () => {
      for (let i = 0; i < quantity; i++) {
        setSizesViewport(`viewport${i + 1}`);
      }
    };

    const setBannerViewport = viewport => {
      const { publication } = props;
      for (let i = 0; i < quantityBanner; i++) {
        const position = i + 1;
        const viewportBanners = publication[`${viewport}_banners_${position}`];
        if (viewportBanners) {
          const viewportBannersArray = viewportBanners.split('x');
          sizesViewport.push([
            publication[viewport],
            [parseInt(viewportBannersArray[0]), parseInt(viewportBannersArray[1])],
          ]);
        }
      }
    };

    const setSizesViewport = viewport => {
      const { publication } = props;
      for (let i = 0; i < quantity; i++) {
        const position = i + 1;
        const width = publication[`${viewport}_width${position}`];
        const height = publication[`${viewport}_height${position}`];
        if (!!width && !!height) {
          sizesViewport.push([publication[viewport], [width, height]]);
        }
      }

      setBannerViewport(viewport);
    };

    getViewportWithSizeImages();

    useMidiaProgramatica(
      () => {
        if (!isLoaded) {
          setLoaded(true);
        }
      },
      getSizeImagesInViewport('1250x0', sizesViewport),
      getSizeImagesInViewport('728x0', sizesViewport),
      getSizeImagesInViewport('0x0', sizesViewport),
      networkCode,
      adUnitPath,
      getOnlySizeImages(sizesViewport),
      id,
      getPits(props.match),
      enableLazyLoad,
      props?.cookies,
      props?.location
    );

    const googleTagDisplayAd = id => {
      googletag.cmd.push(() => {
        googletag.display(id);
      });
    };

    if (enableLazyLoad) {
      return (
        <WrappedComponent id={id} zionSlideShow={zionSlideShow}>
          {!isLoaded ? null : googleTagDisplayAd(id)}
        </WrappedComponent>
      );
    }
    return (
      <Observer onceVisible={() => googleTagDisplayAd(id)}>
        <div>
          <WrappedComponent id={id} zionSlideShow={zionSlideShow} />
        </div>
      </Observer>
    );
  };
  return Enhanced;
};
